<template>
	<div>
		<f-default-header
			:title="$t('pages.pos.title')"
			:subtitle="$t('pages.pos.withoutSales.subtitle')" />
		<f-card>
			<v-row>
				<v-col cols="12">
					<f-card-title>{{ $t("pages.pos.title") }}</f-card-title>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="6">
					<v-select
						v-model="month"
						outlined
						dense
						hide-details="auto"
						:items="months"
						:label="$t('labels.selectMonth')" />
				</v-col>
				<v-col cols="6">
					<v-select
						v-model="year"
						outlined
						dense
						hide-details="auto"
						:items="years"
						:label="$t('labels.selectYear')" />
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-btn elevation="0" @click="goBack">
						{{ $t("labels.btnCancel") }}
					</v-btn>
					&nbsp;
					<v-btn elevation="0" color="primary" @click="report">
						{{ $t("labels.btnReport") }}
					</v-btn>
				</v-col>
			</v-row>
		</f-card>
	</div>
</template>

<script>
import months from "../../data/months";

export default {
	data: () => ({
		language: "pt",
		month: new Date().getMonth() + 1,
		year: new Date().getFullYear(),
		months: [],
		years: [],
	}),
	created() {
		this.language = localStorage.getItem("currentLang") || "pt";

		for (var i = 1; i < 13; i++) {
			let monthStr = i.toString().padStart(2, "0");
			this.months.push({
				text: months[this.language][monthStr],
				value: i,
			});
		}

		const currentYear = new Date().getFullYear();
		for (var i = 2022; i <= currentYear; i++) {
			this.years.push(i);
		}
	},
	methods: {
		goBack() {
			this.$router.push({
				name: "pos-list",
			});
			return;
		},
		async report() {
			const result = await this.$store.dispatch("pos/noSellsReport", {
				month: this.month,
				year: this.year,
				replace: false,
			});

			if (
				typeof result != "undefined" &&
				!result.status &&
				result.body == "this_date_exists_alread"
			) {
				var response = await this.$store.dispatch("dialog/show", {
					title: this.$t("labels.error"),
					content: this.$t("alerts.posNoSellsReportAlreadyExists"),
				});
				if (response) {
					await this.$store.dispatch("pos/noSellsReport", {
						month: this.month,
						year: this.year,
						replace: true,
					});
				}
			}

			this.$store.commit("snackbar/show", {
				content: this.$t("snackbar.posNoSellsReportSuccess"),
				type: "success",
			});
			this.goBack();
		},
	},
};
</script>
